body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF;

}

:root {
  --font-family: 'Rubik', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
}


svg {
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .desktop {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .mobile {
    display: none !important;
  }
  
  body {
    overflow-y: scroll;
  }
}
